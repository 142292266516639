import { createGlobalStyle } from "styled-components";
import normalize from "styled-normalize";

export const theme = {
  font: {
    sans: 'Cambay, sans-serif',
    serif: 'Arvo, sans',
    monospace: '"Ubuntu Mono", monospace',
    h1: '3rem',
    h2: '2.4rem',
    h3: '2.2rem'
  },
  color: {
    primary: '#a10000', //red
    secondary: '#d99939', //yellow
    tertiary: '#085593', //blue
    background: '#081024', //dark blue
    light: '#aed3dd', //light blue
    dark: '#0f0f0f' //dark gray
  },
  breakpoints: {
    xs: '0',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1400px',
  },
}

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  * {
    box-sizing: border-box;
  }

  html {
    height: 100%;
  }

  body {
    background: ${({ theme }) => theme.color.dark};
    font-family: 'IM Fell English', serif;
    height: 100%;
  }

  #gatsby-focus-wrapper {
    height: 100%;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.color.secondary};
    transition: color 0.5s ease;
    &:hover {
      color: white;
    }
  }
`;