/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"

import { ThemeProvider } from 'styled-components'
import styled from "styled-components"
import { GlobalStyle, theme } from "./globalStyle"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const MainWrapper = styled.div`
    margin: 0 auto;
    max-width: ${({ theme }) => theme.breakpoints.lg};
    height: 100%;
  `

const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)


  return (
    <>
    <ThemeProvider theme={theme}>
        <GlobalStyle />
        <MainWrapper>
          {children}
        </MainWrapper>
    </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
